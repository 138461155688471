import React, { Component } from 'react';
import Header from './components/Header';
import MainSection from './components/MainSection';
import ReactGA from 'react-ga';
import './App.css';

class App extends Component {
  componentDidMount() {
    ReactGA.initialize('G-MYRVR9FE88');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidUpdate() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div>
        <Header />
        <MainSection />
      </div>
    );
  }
}

export default App;
